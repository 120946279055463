import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Menu,
    MenuButton,
    Avatar,
    AvatarBadge,
    MenuList,
    Link,
    MenuItem,
    IconButton
} from '@chakra-ui/react';
import { StreamChat } from 'stream-chat';

import { FaArrowRightLong } from 'react-icons/fa6';

import { getChatTokenAndRedirectPage } from '../common/methods/methods.js';
import {
    AGENT,
    BOTH,
    BUYER,
    FIRST_NAME,
    ID,
    USER_TYPE
} from '../../utils/constants.js';

import '../Generic.css';
import './ViewNavigationBar.css';
import { useAuth } from '../auth/useAuth.js';

const chatClient = StreamChat.getInstance("ufb64fqzh9zr");

function ViewNavigationBar(props) {

    const navigate = useNavigate();

    const user = props.user;
    const [viewType] = useState(props.viewType);
    const [ displaySwitchButton ] = useState(props.displaySwitchButton)
    let userId = user[ID];

    const { logout } = useAuth();

    return (
        <div className="view_navigation_bar">
            <div style={{
                display: "flex",
            }}>
                <Link
                    style={{
                        textDecoration: "none"
                    }}
                    href={viewType === BUYER ? "/user/buyer/dashboard/homes/" + userId : "/user/agent/dashboard/leads/" + userId}
                    className="view_navigation_bar-logo-box"
                >
                    <img 
                        alt="rezied_logo"
                        className="view_navigation_bar-logo" 
                        src={require("../../Images/logo/logo_500_500_green_background.png")}
                    />
                    <p className="view_navigation_bar-logo-title">ezied</p>
                </Link>
                <div style={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    height: "20px",
                    margin: "calc((50px - 20px)/2) 0px calc((50px - 20px)/2) 15px",
                    padding: "2.5px 7.5px 2.5px 7.5px",
                }}>
                    <p style={{
                        color: "#05b96b",
                        fontSize: "0.7em",
                        fontWeight: "bold",
                        lineHeight: "15px",
                    }}>
                        {viewType}
                    </p>
                </div>
            </div>
            <div style={{
                display: "flex",
                zIndex: "30",
            }}>
                {
                    displaySwitchButton ? 
                    (user[USER_TYPE] !== BOTH ? null :
                    viewType === BUYER ?
                    <div className="view_navigation_bar__switch_button hover" onMouseDown={() => getChatTokenAndRedirectPage(chatClient, userId, user[FIRST_NAME], AGENT, "/user/agent/dashboard/leads/" + userId)}>
                        Agent view
                        <FaArrowRightLong className="view_navigation_bar__switch_button_icon"/>
                    </div>
                    :
                    <div className="view_navigation_bar__switch_button hover" onMouseDown={() => getChatTokenAndRedirectPage(chatClient, userId, user[FIRST_NAME], BUYER, "/user/buyer/dashboard/agents/" + userId)}>
                        Buyer view
                        <FaArrowRightLong className="view_navigation_bar__switch_button_icon"/>
                    </div>)
                    : null
                }
                <Menu 
                    placement={"bottom-end"}
                >
                    <MenuButton
                        style={{
                            backgroundColor: "transparent",
                            color: "grey",
                            margin: "calc((50px - 40px)/2) 25px calc((50px - 40px)/2) 0px",
                        }}
                        as={IconButton}
                        aria-label='Options'
                        rightIcon={
                            <Avatar src={user && user["profile_picture_url"] && user["profile_picture_url"].length > 0 ? user["profile_picture_url"] : ''} size='sm'>
                                <AvatarBadge boxSize='1.0em' bg='green.500' />
                            </Avatar>
                        }
                    />
                    <MenuList style={{
                        border: "none",
                        borderRadius: "4px !important",
                        boxShadow: "0 2px 3px -3px #eeeeee, 0 3px 3px 2px #eeeeee, 0 1px 3px 1px rgba(13, 13, 13, 0.12)",
                        margin: "-7.5px 0px 0px 0px",
                        paddingBottom: "5px",
                        paddingTop: "0px"
                    }}>
                        <MenuItem className="view_navigation_bar__menu_logo_element">
                            Rezied
                        </MenuItem>
                        <Link className="view_navigation_bar__link">
                            <MenuItem className="view_navigation_bar__link_menu_item">
                            {/* <MenuItem icon={<FaQuestionCircle />}> */}
                                Help & Support
                            </MenuItem>
                        </Link>
                        <Link href={"/contact-us/" + userId + "?view_type=" + viewType} className="view_navigation_bar__link">
                            <MenuItem className="view_navigation_bar__link_menu_item">
                            {/* <MenuItem icon={<FaExclamationCircle />}> */}
                                Contact Us
                            </MenuItem>
                        </Link>
                        <Link className="view_navigation_bar__link" href={"/"} onMouseDown={() => {
                            logout().then(() => {
                                localStorage.removeItem("user");
                                localStorage.removeItem("userProfilePictureURL");
                                navigate('/');
                            });
                        }}>
                            <MenuItem className="view_navigation_bar__link_menu_item">
                            {/* <MenuItem icon={<FaSignOutAlt />}> */}
                                Sign Out
                            </MenuItem>
                        </Link>
                    </MenuList>
                </Menu>
            </div>
        </div>
    );
}

export default ViewNavigationBar;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Radar from 'radar-sdk-js';

import {
    Box,
    Button,
    Checkbox,
    IconButton,
    Input,
    Textarea,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Tabs,
    TabPanel,
    TabPanels,
    Tooltip,
    Stack,
    Select,
    useSteps,
    useToast,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

import {
    ABOUT_DESCRIPTION,
    APARTMENT,
    ASSET_SPECIALTY,
    CONDOMINIUM,
    DELETE,
    DUPLEX,
    ID,
    FIRM,
    GET,
    LICENSE_NUMBER,
    FIRST_NAME,
    FORECLOSURE,
    LICENSE_TYPE,
    MANUFACTURED,
    MULTIPLEX,
    PATCH,
    REPRESENTATION_STYLE,
    SHORT_SALE,
    SINGLE_FAMILY_HOME,
    TOWNHOME,
    TRAILER,
    POST,
    WORKING_STYLE,
    SALE_EXPERTISE,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_DELETE_TIMEOUT,
    DEFAULT_PATCH_TIMEOUT,
    DEFAULT_POST_TIMEOUT,
    NUMBER_OF_AREAS_OF_EXPERTISE
} from '../../../utils/constants.js';
import { axiosGetAgent } from '../../common/methods/methods.js';
import { checkRequireAgentSignUp } from '../../../utils/utility.js';

import { MdClose } from 'react-icons/md';

import './AgentSignUpInformationModal.css';

function AgentSignUpInformationModal(props) {

    const user = props.user;
    let userId = user[ID];
    console.log(user);

    // Set this to null to close the modal in parent component.
    const setFinishSignUpFormAgent = props.setFinishSignUpFormAgent;

    const [profileForm, setProfileForm] = useState([]);
    const [aboutDescriptionTextareaValue, setAboutDescriptionTextareaValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [expertiseAreasAutocompleteAddresses, setExpertiseAreasAutocompleteAddresses] = useState(null);
    const [formExpertiseAreas, setFormExpertiseAreas] = useState([]);

    const [firmError, setFirmError] = useState(null);
    const [licenseNumberError, setLicenseNumberError] = useState(null);
    const [aboutError, setAboutError] = useState(null);
    const [licenseError, setLicenseError] = useState(null);
    const [representationStyleError, setRepresentationStyleError] = useState(null);
    const [workingStyleError, setWorkingStyleError] = useState(null);
    // const [saleExpertiseError, setSaleExpertiseError] = useState(null);
    const [assetSpecialtiesError, setAssetSpecialtiesError] = useState(null);
    const [areasOfExpertiseError, setAreasOfExpertiseError] = useState(null);

    const [triggerFetchAreasOfExpertise, setTriggerFetchAreasOfExpertise] = useState(false);

    const toast = useToast();

    // Fetch agent's areas of Expertise.
    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/agent/expertise_areas/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                let areasOfExpertise = response.data;
                setFormExpertiseAreas(areasOfExpertise);
            }
        }).catch(error => {
            // TODO
        })
    }, [userId, triggerFetchAreasOfExpertise]);

    const steps = [
        { title: 'First', description: 'General' },
        { title: 'Second', description: 'Agent' },
        { title: 'Third', description: 'Specialties' },
    ];

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    const [tabIndex, setTabIndex] = useState(0);

    const checkAndAlertGeneralTabFormErrors = () => {

        if (profileForm === null || profileForm === undefined) {
            return true;
        }

        var hasErrors = false;

        // Check form errors.
        if (!user[FIRM] || user[FIRM].length === 0){
            if (!profileForm[FIRM] || profileForm[FIRM].length === 0) {
                hasErrors = true;
                setFirmError("Firm is required");
            } else if (profileForm[FIRM].length > 45) {
                hasErrors = true;
                setFirmError("Firm cannot be more than 45 characters");
            }
        }

        if (!user[LICENSE_NUMBER] || user[LICENSE_NUMBER].length === 0) {
            if (!profileForm[LICENSE_NUMBER] || profileForm[LICENSE_NUMBER].length === 0) {
                hasErrors = true;
                setLicenseNumberError("License Number is required");
            } else if (profileForm[LICENSE_NUMBER].length > 45) {
                hasErrors = true;
                setLicenseNumberError("License Number cannot be more than 45 characters");
            }
        }

        if (!user[ABOUT_DESCRIPTION] || user[ABOUT_DESCRIPTION].length === 0) {
            if (aboutDescriptionTextareaValue.length === 0) {
                hasErrors = true;
                setAboutError("Description is required");
            } else if (aboutDescriptionTextareaValue.length > 500) {
                hasErrors = true;
                setAboutError("Description cannot be more than 500 characters");
            }
        }

        return hasErrors;
    }

    const checkAndAlertAgentTabFormErrors = () => {

        if (profileForm === null || profileForm === undefined) {
            return true;
        }

        var hasErrors = false;

        // Check form errors.
        if (!user[LICENSE_TYPE] || user[LICENSE_TYPE].length === 0) {
            if (!profileForm[LICENSE_TYPE] || profileForm[LICENSE_TYPE].length === 0) {
                hasErrors = true;
                setLicenseError("License type is required");
            }
        }

        if (!user[REPRESENTATION_STYLE] || user[REPRESENTATION_STYLE].length === 0) {
            if (!profileForm[REPRESENTATION_STYLE] || profileForm[REPRESENTATION_STYLE].length === 0) {
                hasErrors = true;
                setRepresentationStyleError("Representation type is required");
            }
        }

        if (!user[WORKING_STYLE] || user[WORKING_STYLE].length === 0) {
            if (!profileForm[WORKING_STYLE] || profileForm[WORKING_STYLE].length === 0) {
                hasErrors = true;
                setWorkingStyleError("Working style is required");
            }
        }
        
        // if (!user[SALE_EXPERTISE] || user[SALE_EXPERTISE].length === 0) {
        //     let saleExpertiseCheckboxes = document.getElementsByClassName("sale_expertise__checkbox");
        //     // Check if at least one checkbox is checked.
        //     let atLeastOneChecked = false;
        //     for (let i = 0; i < saleExpertiseCheckboxes.length; i++) {
        //         let chakraCheckboxElement = saleExpertiseCheckboxes[i];
        //         // The actual element is the first child, the input.
        //         let checkboxInput = chakraCheckboxElement.children[0];
        //         if (checkboxInput.checked === true) {
        //             atLeastOneChecked = true;
        //         }
        //     }
        //     if (!atLeastOneChecked) {
        //         setSaleExpertiseError("At least one sale expertise is required")
        //         hasErrors = true;
        //     }
        // }
        return hasErrors;            
    }

    const checkAndAlertSpecialtyTabFormErrors = () => {

        if (!profileForm) return true;

        var hasErrors = false;

        // Check form errors.   
        if (!user[ASSET_SPECIALTY] || user[ASSET_SPECIALTY].length === 0) {
            let assetSpecialtiesCheckboxes = document.getElementsByClassName("asset_specialty__checkbox");
            // Check if at least one checkbox is checked.
            let atLeastOneChecked = false;
            for (let i = 0; i < assetSpecialtiesCheckboxes.length; i++) {
                let chakraCheckboxElement = assetSpecialtiesCheckboxes[i];
                // The actual element is the first child, the input.
                let checkboxInput = chakraCheckboxElement.children[0];
                if (checkboxInput.checked === true) {
                    atLeastOneChecked = true;
                }
            }
            if (!atLeastOneChecked) {
                setAssetSpecialtiesError("At least one asset specialty is required")
                hasErrors = true;
            }
        }

        // Check for areas of coverage. Form does not have any and user does not have any existing.
        if ((!formExpertiseAreas || formExpertiseAreas.length === 0) && (user[NUMBER_OF_AREAS_OF_EXPERTISE] === 0)) {
            setAreasOfExpertiseError("At least one area is required");
            hasErrors = true;
        }

        return hasErrors; 
    }

    const handleAboutDescriptionTextareaChange = (e) => {
        setAboutError(null);
        setAboutDescriptionTextareaValue(e.target.value);
        profileForm[ABOUT_DESCRIPTION] = e.target.value;
    }

    const handleProfileFormChange = (event) => {
        // Reset our errors.
        if (event.target.name === FIRM) {
            setFirmError(null);
        }
        if (event.target.name === LICENSE_NUMBER) {
            setLicenseNumberError(null);
        }
        if (event.target.name === ABOUT_DESCRIPTION) {
            setAboutError(null);
        }
        if (event.target.name === LICENSE_TYPE) {
            setLicenseError(null);
        }
        if (event.target.name === REPRESENTATION_STYLE) {
            setRepresentationStyleError(null);
        }
        if (event.target.name === WORKING_STYLE) {
            setWorkingStyleError(null);
        }
        
        profileForm[event.target.name] = event.target.value;
    }

    const getSaleExpertiseValues = () => {

        let saleExpertiseValues = [];
        let saleExpertiseCheckboxes = document.getElementsByClassName("sale_expertise__checkbox");
        for (let i = 0; i < saleExpertiseCheckboxes.length; i++) {
            let chakraCheckboxElement = saleExpertiseCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                saleExpertiseValues.push(checkboxInput.value);
            }
        }
        return saleExpertiseValues;
    }

    const getAssetSpecialtyValues = () => {
        let assetSpecialtyValues = [];
        let assetSpecialtyCheckboxes = document.getElementsByClassName("asset_specialty__checkbox");
        for (let i = 0; i < assetSpecialtyCheckboxes.length; i++) {
            let chakraCheckboxElement = assetSpecialtyCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                assetSpecialtyValues.push(checkboxInput.value);
            }
        }
        return assetSpecialtyValues;
    }

    const handleDeleteAreaOfExpertise = (area_id) => {
        axios({
            method: DELETE,
            url: '/api/v1/user/agent/expertise_area/' + user[ID] + '/' + area_id,
            timeout: DEFAULT_DELETE_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                setTriggerFetchAreasOfExpertise(!triggerFetchAreasOfExpertise);
            }
        }).catch(error => {
            // TODO
        })
    }

    const handleAddAreaOfExpertise = (address) => {
        axios({
            method: POST,
            url: '/api/v1/user/agent/expertise_area/' + user[ID],
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                agent_id: user[ID],
                coordinates: {
                    longitude: parseFloat(address["longitude"]),
                    latitude: parseFloat(address["latitude"]),
                },
                formatted_address: address["formattedAddress"],
            },
        }).then(response => {
            if (200 === response.status) {
                setTriggerFetchAreasOfExpertise(!triggerFetchAreasOfExpertise);
            }
        }).catch(error => {
            if (409 === error.response.status) {
                toast({
                    title: 'Duplicate area of expertise',
                    description: "You've already marked that area of expertise",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        })
    }

    const handleUpdateUserProfile = (data) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/agent/profile/' + user[ID],
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data,
        }).then(response => {
            if (200 === response.status) {
                axiosGetAgent(user[ID]).then(agent => {
                    if (agent) {
                        if (checkRequireAgentSignUp(agent) === true) {
                            toast({
                                title: 'Missing required information',
                                description: "We still need some information for your profile.",
                                status: 'info',
                                duration: 3000,
                                isClosable: true,
                                position: 'top'
                            });
                        } else {
                            setFinishSignUpFormAgent(null);
                        };
                    }
                }).catch(error => {
                    // TODO
                });
            }
        }).catch(error => {
            // TODO
        }, []);
    }

    const handleAreasOfExpertiseInputChange = (e) => {
        setAreasOfExpertiseError(null);
        let val = e.target.value;
        setInputValue(val);
        if (val.length <= 2) {
            setExpertiseAreasAutocompleteAddresses(null);
            return;
        }
        Radar.autocomplete({
            query: val,
            limit: 10,
            country: 'US',
        }).then(response => {
            // We want to let users search for the following.
            // 1. Locality's
            // 2. Zip Codes
            let addresses = response.addresses.filter(e =>
                e["formattedAddress"].includes("USA") && (e["layer"] === "locality" || 
                (e["postalCode"] && e["postalCode"].includes(val)))
            );
            setExpertiseAreasAutocompleteAddresses(addresses);
        }).catch(error => {
            // TODO
        })
    }

    const renderExpertiseAreasAutocompleteAddresses = () => {

        let renderedAddresses = [];

        for (let i = 0; i < expertiseAreasAutocompleteAddresses.length; i++) {
            let formattedAddress = expertiseAreasAutocompleteAddresses[i]["formattedAddress"];
            renderedAddresses.push(
                <div key={i + "" + formattedAddress} className="autocomplete__interested_address"
                    onMouseDown={() => {
                        handleAddAreaOfExpertise(expertiseAreasAutocompleteAddresses[i]);
                        // setFormExpertiseAreas()
                        setExpertiseAreasAutocompleteAddresses(null);
                        setInputValue('');
                    }}
                style={{
                    cursor: "pointer",
                    padding: "7.5px 10px 7.5px 10px",
                }}>
                    <p style={{
                        fontSize: "0.9em"
                    }}>
                        {formattedAddress}
                    </p>
                </div>
            )
        }
        
        return (
            <div style={{
                backgroundColor: "white",
                border: "1px solid #cccccc",
                borderRadius: "4px",
                position: "absolute",
                width: "100%",
                zIndex: "2",
            }}>
                {renderedAddresses}
            </div>
        )
    }

    const renderFormExpertiseAreas = () => {

        if (!formExpertiseAreas || formExpertiseAreas.length === 0) return;

        let areas = [];
        for (let i = 0; i < formExpertiseAreas.length; i++) {
            let area = formExpertiseAreas[i];
            let address;
            if (area.formattedAddress) {
                address = area.formattedAddress;
            } else if (area.formatted_address) {
                address = area.formatted_address;
            } else {
                address = "N/A";
            }
            areas.push(
                <div style={{
                    backgroundColor: "#efefef",
                    borderRadius: "8px",
                    display: "flex",
                    fontSize: "0.8em",
                    margin: "0px 10px 0px 0px",
                    padding: "7.5px 12.5px 7.5px 12.5px",
                }}>
                    <p>
                        {address}
                    </p>
                    <MdClose 
                        className="hover"
                        onMouseDown={() => {
                            handleDeleteAreaOfExpertise(area[ID]);
                        }}
                        style={{
                            cursor: "pointer",
                            height: "15px",
                            margin: "1.5px 0px 0px 10px",
                            width: "15px",
                        }}
                    />
                </div>
            );
        }

        return (
            <div style={{
                display: "flex",
                margin: "15px 0px 0px 0px",
            }}>
                {areas}
            </div>
        );
    }

    if (!user) return;

    return (
        <div style={{
            overflowY: "scroll",
            overscrollBehaviorY: "contain",
        }}>
            <div style={{
                backgroundColor: "#efefef",
                opacity: '0.8',
                height: "calc(100vh - 50px)",
                position: "absolute",
                top: '50px',
                left: '0',
                width: '100%',
                zIndex: "10",
            }}>
            </div>
            <div style={{
                backgroundColor: "white",
                borderRadius: "12px",
                left: "calc((100% - 700px)/2)",
                padding: "50px 0px 50px 0px",
                right: "calc((100% - 700px)/2)",
                position: "absolute",
                width: '700px',
                zIndex: "11",
            }}>
                <div style={{
                    backgroundColor: "white !important",
                    margin: "auto",
                    width: "500px",
                }}>
                    <p style={{
                        fontWeight: "bold",
                        fontSize: "1.4em",
                    }}>
                        Welcome to Rezied {user[FIRST_NAME]},
                    </p>
                    <p style={{
                        fontSize: "0.9em",
                        margin: "10px 0px 0px 0px",
                    }}>
                        We're excited to have you here. We need some additional information to make you stand out to prospective clients.
                    </p>
                    <p style={{
                        color: "grey",
                        fontSize: "0.9em",
                        margin: "5px 0px 0px 0px",
                    }}>
                        (You can always change these answers later in your profile)
                    </p>
                    <Stepper colorScheme='green' index={activeStep} size='sm' style={{
                        margin: "25px 0px 15px 0px",
                    }}>
                        {steps.map((step, index) => (
                            <Step key={index}>
                            <StepIndicator>
                                <StepStatus
                                    complete={<StepIcon />}
                                    incomplete={<StepNumber />}
                                    active={<StepNumber />}
                                />
                            </StepIndicator>

                            <Box flexShrink='0'>
                                <StepTitle>{step.title}</StepTitle>
                                <StepDescription>{step.description}</StepDescription>
                            </Box>

                            <StepSeparator />
                            </Step>
                        ))}
                    </Stepper>
                    <Tabs index={tabIndex} onChange={() => {}}>
                        <TabPanels>
                            <TabPanel style={{
                                padding: "0px",
                            }}>
                                <div style={{
                                    margin: "15px 0px 0px 0px",
                                }}>
                                    <p style={{
                                        fontSize: "0.9em",
                                    }}>
                                        What firm do you work for?
                                    </p>
                                    <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={firmError}
                                        isOpen={firmError !== null}
                                        placement='top-end'
                                    >
                                        <Input
                                            className="default_input"
                                            name={FIRM}
                                            onChange={handleProfileFormChange}
                                            placeholder={user && user[FIRM] !== "" ? user[FIRM] : null}
                                            defaultValue={user && user[FIRM] !== "" ? user[FIRM] : null}
                                            style={{
                                                margin: "5px 0px 0px 0px",
                                            }}
                                        >
                                        </Input>
                                    </Tooltip>
                                </div>
                                <div style={{
                                    margin: "15px 0px 0px 0px",
                                }}>
                                    <p style={{
                                        fontSize: "0.9em",
                                    }}>
                                        What is your license number?
                                    </p>
                                    <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={licenseNumberError}
                                        isOpen={licenseNumberError !== null}
                                        placement='top-end'
                                    >
                                        <Input
                                            className="default_input"
                                            name={LICENSE_NUMBER}
                                            onChange={handleProfileFormChange}
                                            placeholder={user && user[LICENSE_NUMBER] !== "" ? user[LICENSE_NUMBER] : null}
                                            defaultValue={user && user[LICENSE_NUMBER] !== "" ? user[LICENSE_NUMBER] : null}
                                            style={{
                                                margin: "5px 0px 0px 0px",
                                            }}
                                        >
                                        </Input>
                                    </Tooltip>
                                </div>
                                <div style={{
                                    margin: "20px 0px 0px 0px",
                                }}>
                                    <p style={{
                                        fontSize: "0.9em",
                                    }}>
                                        Tell us about yourself. This is what clients will see when choosing Agents.
                                    </p>
                                    <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={aboutError}
                                        isOpen={aboutError !== null}
                                        placement='top-end'
                                    >
                                        <Textarea
                                            className="default_input" 
                                            placeholder={user && user[ABOUT_DESCRIPTION] !== "" ?  user[ABOUT_DESCRIPTION] : "I'm an agent primarily working in the New York City area..."} 
                                            defaultValue={user && user[ABOUT_DESCRIPTION] !== "" ? user[ABOUT_DESCRIPTION] : null}
                                            resize={"none"} 
                                            onChange={handleAboutDescriptionTextareaChange}
                                            name={ABOUT_DESCRIPTION}
                                            style={{
                                                height: "125px",
                                                margin: "5px 0px 0px 0px",
                                            }}
                                        >
                                        </Textarea>
                                    </Tooltip>
                                    <p style={{
                                        color: "grey",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        fontSize: "0.8em",
                                        margin: "5px 0px 0px 0px",
                                    }}>                                        
                                        {500 - parseInt(aboutDescriptionTextareaValue.length)} / 500 characters left
                                    </p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    margin: "20px 0px 0px 0px",
                                }}>
                                    <IconButton className="hover" size='sm' style={{
                                        backgroundColor: "#05b96b",
                                        color: "white",
                                    }} icon={<ArrowForwardIcon/>} isRound={true} onMouseDown={() => {
                                        if (checkAndAlertGeneralTabFormErrors()) {
                                            return;
                                        }
                                        setActiveStep(1);
                                        setTabIndex(1);
                                    }}>
                                    </IconButton>
                                </div>
                            </TabPanel>
                            <TabPanel style={{
                                padding: "0px",
                            }}>
                                <div style={{
                                    margin: "15px 0px 0px 0px",
                                }}>
                                    <p style={{
                                        fontSize: "0.9em",
                                    }}>
                                        What license do you hold?
                                    </p>
                                    <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={licenseError}
                                        isOpen={licenseError !== null}
                                        placement='top-end'
                                    >
                                        <Select 
                                            name={LICENSE_TYPE}
                                            className="default_input"
                                            style={{
                                                margin: "5px 0px 0px 0px",
                                            }}
                                            defaultValue={user && user[LICENSE_TYPE] ? user[LICENSE_TYPE] : null}
                                            onChange={handleProfileFormChange}
                                        >
                                            <option hidden>-</option>
                                            <option value="ManagingBroker">Managing Broker</option>
                                            <option value="AssociateBroker">Associate Broker</option>
                                            <option value="Salesperson">Salesperson</option>
                                        </Select>
                                    </Tooltip>
                                </div>
                                <div style={{
                                    margin: "20px 0px 0px 0px",
                                }}>
                                    <p style={{
                                        fontSize: "0.9em",
                                    }}>
                                        What's your representation style?
                                    </p>
                                    <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={representationStyleError}
                                        isOpen={representationStyleError !== null}
                                        placement='top-end'
                                    >
                                        <Select
                                            name={REPRESENTATION_STYLE}
                                            className="default_input"
                                            defaultValue={user && user[REPRESENTATION_STYLE] ? user[REPRESENTATION_STYLE] : null}
                                            style={{
                                                margin: "5px 0px 0px 0px",
                                            }}
                                            onChange={handleProfileFormChange}
                                        >
                                            <option hidden>-</option>
                                            <option value="Customer">Customer</option>
                                            <option value="Client">Client</option>
                                        </Select>
                                    </Tooltip>
                                </div>
                                <div style={{
                                    margin: "20px 0px 0px 0px",
                                }}>
                                    <p style={{
                                        fontSize: "0.9em",
                                    }}>
                                        What's your working style?
                                    </p>
                                    <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={workingStyleError}
                                        isOpen={workingStyleError !== null}
                                        placement='top-end'
                                    >
                                        <Select
                                            name={WORKING_STYLE}
                                            className="default_input"
                                            defaultValue={user && user[WORKING_STYLE] ? user[WORKING_STYLE] : null}
                                            style={{
                                                margin: "5px 0px 0px 0px",
                                            }}
                                            onChange={handleProfileFormChange}
                                        >
                                            <option hidden>-</option>
                                            <option value="PartOfTeam">Part of a team</option>
                                            <option value="SoleLead">Sole lead</option>
                                        </Select>
                                    </Tooltip>
                                </div>
                                <div style={{
                                    margin: "20px 0px 0px 0px",
                                }}>
                                    <p style={{
                                        fontSize: "0.9em",
                                    }}>
                                        What are your sale expertise?
                                    </p>
                                    {/* <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={saleExpertiseError}
                                        isOpen={saleExpertiseError !== null}
                                        placement='top-end'
                                    > */}
                                        <Stack>
                                            <Checkbox 
                                                className="sale_expertise__checkbox"
                                                name={SHORT_SALE}
                                                value="ShortSale"
                                                defaultChecked={user && user[SALE_EXPERTISE] !== null && user[SALE_EXPERTISE].includes("ShortSale")}
                                                onChange={() => {
                                                    // setSaleExpertiseError(null);
                                                    profileForm[SALE_EXPERTISE] = getSaleExpertiseValues();
                                                    setProfileForm(profileForm);
                                                }}
                                            >
                                                Short Sale
                                            </Checkbox>
                                            <Checkbox 
                                                className="sale_expertise__checkbox"
                                                name={FORECLOSURE}
                                                value="Foreclosure"
                                                defaultChecked={user && user[SALE_EXPERTISE] !== null && user[SALE_EXPERTISE].includes("Foreclosure")}
                                                onChange={() => {
                                                    // setSaleExpertiseError(null);
                                                    profileForm[SALE_EXPERTISE] = getSaleExpertiseValues();
                                                    setProfileForm(profileForm);
                                                }}
                                            >
                                                Foreclosure
                                            </Checkbox>
                                        </Stack>
                                    {/* </Tooltip> */}
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "20px 0px 0px 0px",
                                }}>
                                    <IconButton className="hover" size='sm' style={{
                                        backgroundColor: "#efefef",
                                        color: "black",
                                    }} icon={<ArrowBackIcon/>} isRound={true} onMouseDown={() => {
                                        setActiveStep(0);
                                        setTabIndex(0);
                                    }}>
                                    </IconButton>
                                    <IconButton className="hover" size='sm' style={{
                                        backgroundColor: "#05b96b",
                                        color: "white",
                                    }} icon={<ArrowForwardIcon/>} isRound={true} onMouseDown={() => {
                                        if (checkAndAlertAgentTabFormErrors()) {
                                            return;
                                        }
                                        setActiveStep(2);
                                        setTabIndex(2);
                                    }}>
                                    </IconButton>
                                </div>
                            </TabPanel>
                            <TabPanel style={{
                                padding: "0px",
                            }}>
                                <div style={{
                                    margin: "20px 0px 0px 0px",
                                }}>
                                    <p style={{
                                        fontSize: "0.9em",
                                    }}>
                                        What are your asset specialties?
                                    </p>
                                    <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={assetSpecialtiesError}
                                        isOpen={assetSpecialtiesError !== null}
                                        placement='top-end'
                                    >
                                        <Stack style={{
                                            margin: "5px 0px 0px 0px",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                width: "100%"
                                            }}>
                                                <div style={{
                                                    display: "block",
                                                    width: "50%",
                                                }}>
                                                    <Checkbox
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        name={SINGLE_FAMILY_HOME}
                                                        value="SingleFamilyHome"
                                                        defaultChecked={user && user[ASSET_SPECIALTY] !== null && user[ASSET_SPECIALTY].includes("SingleFamilyHome")}
                                                        className="asset_specialty__checkbox"
                                                        onChange={() => {
                                                            setAssetSpecialtiesError(null);
                                                            profileForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                            setProfileForm(profileForm);
                                                        }}
                                                    >
                                                        Single Family Home
                                                    </Checkbox>
                                                    <Checkbox 
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        name={APARTMENT}
                                                        value="Apartment"
                                                        defaultChecked={user && user[ASSET_SPECIALTY] !== null && user[ASSET_SPECIALTY].includes("Apartment")}
                                                        className="asset_specialty__checkbox"
                                                        onChange={() => {
                                                            setAssetSpecialtiesError(null);
                                                            profileForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                            setProfileForm(profileForm);
                                                        }}
                                                    >
                                                        Apartment
                                                    </Checkbox>
                                                    <Checkbox 
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        name={TOWNHOME}
                                                        value="Townhome"
                                                        className="asset_specialty__checkbox"
                                                        defaultChecked={user && user[ASSET_SPECIALTY] !== null && user[ASSET_SPECIALTY].includes("Townhome")}
                                                        onChange={() => {
                                                            setAssetSpecialtiesError(null);
                                                            profileForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                            setProfileForm(profileForm);
                                                        }}
                                                    >
                                                        Townhome
                                                    </Checkbox>
                                                    <Checkbox 
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        name={DUPLEX}
                                                        value="Duplex"
                                                        defaultChecked={user && user[ASSET_SPECIALTY] !== null && user[ASSET_SPECIALTY].includes("Duplex")}
                                                        className="asset_specialty__checkbox"
                                                        onChange={() => {
                                                            setAssetSpecialtiesError(null);
                                                            profileForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                            setProfileForm(profileForm);
                                                        }}
                                                    >
                                                        Duplex
                                                    </Checkbox>
                                                </div>
                                                <div style={{
                                                    display: "block",
                                                    width: "50%",
                                                }}>
                                                    <Checkbox 
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        name={MULTIPLEX}
                                                        value="Multiplex"
                                                        className="asset_specialty__checkbox"
                                                        defaultChecked={user && user[ASSET_SPECIALTY] !== null && user[ASSET_SPECIALTY].includes("Multiplex")}
                                                        onChange={() => {
                                                            setAssetSpecialtiesError(null);
                                                            profileForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                            setProfileForm(profileForm);
                                                        }}
                                                    >
                                                        Multiplex
                                                    </Checkbox>
                                                    <Checkbox 
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        name={CONDOMINIUM}
                                                        value="Condominium"
                                                        className="asset_specialty__checkbox"
                                                        defaultChecked={user && user[ASSET_SPECIALTY] !== null && user[ASSET_SPECIALTY].includes("Condominium")}
                                                        onChange={() => {
                                                            setAssetSpecialtiesError(null);
                                                            profileForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                            setProfileForm(profileForm);
                                                        }}
                                                    >
                                                        Condominium
                                                    </Checkbox>
                                                    <Checkbox 
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        name={TRAILER}
                                                        value="Trailer"
                                                        className="asset_specialty__checkbox"
                                                        defaultChecked={user && user[ASSET_SPECIALTY] !== null && user[ASSET_SPECIALTY].includes("Trailer")}
                                                        onChange={() => {
                                                            setAssetSpecialtiesError(null);
                                                            profileForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                            setProfileForm(profileForm);
                                                        }}
                                                    >
                                                        Trailer
                                                    </Checkbox>
                                                    <Checkbox 
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        name={MANUFACTURED}
                                                        value="Manufactured"
                                                        className="asset_specialty__checkbox"
                                                        defaultChecked={user && user[ASSET_SPECIALTY] !== null && user[ASSET_SPECIALTY].includes("Manufactured")}
                                                        onChange={() => {
                                                            setAssetSpecialtiesError(null);
                                                            profileForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                            setProfileForm(profileForm);
                                                        }}
                                                    >
                                                        Manufactured
                                                    </Checkbox>
                                                </div>
                                            </div>
                                        </Stack>
                                    </Tooltip>
                                </div>
                                <div style={{
                                    margin: "20px 0px 0px 0px",
                                }}>
                                    <p style={{
                                        fontSize: "0.9em",
                                    }}>
                                        What areas do you cover?
                                    </p>
                                    <p style={{
                                        color: "grey",
                                        fontSize: "0.9em",
                                        margin: "5px 0px 0px 0px",
                                    }}>
                                        (Add up to 3 areas. You can always add more later)
                                    </p>
                                    <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={areasOfExpertiseError}
                                        isOpen={areasOfExpertiseError !== null}
                                        placement='top-end'
                                    >
                                        <Input 
                                            value={inputValue}
                                            // Disable the input if they have more than 3 areas already.
                                            isDisabled={formExpertiseAreas && formExpertiseAreas.length >= 3}
                                            className="default_input" 
                                            onChange={handleAreasOfExpertiseInputChange}
                                            placeholder="Search for an area or zip code"
                                            style={{
                                                margin: "5px 0px 0px 0px",
                                            }}
                                        >
                                        </Input>
                                    </Tooltip>
                                    <p style={{
                                        color: "red",
                                        fontSize: "0.7em",
                                    }}>
                                        {
                                            inputValue && inputValue.length > 0 && inputValue.length <= 2 ? 
                                            "Please enter at least two characters" : ""
                                        }
                                    </p>
                                    {
                                        expertiseAreasAutocompleteAddresses && expertiseAreasAutocompleteAddresses.length > 0 ?
                                        <div style={{
                                            position: "relative",
                                            width: "100%"
                                        }}>
                                            {renderExpertiseAreasAutocompleteAddresses()}
                                        </div> : null
                                    }
                                    <div>
                                        {renderFormExpertiseAreas()}
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "20px 0px 0px 0px",
                                }}>
                                    <IconButton className="hover" size='sm' style={{
                                        backgroundColor: "#efefef",
                                        color: "black",
                                    }} icon={<ArrowBackIcon/>} isRound={true} onMouseDown={() => {
                                        setActiveStep(1);
                                        setTabIndex(1);
                                    }}>
                                    </IconButton>
                                    <Button className="positive_button hover" rightIcon={<ArrowForwardIcon/>} size='sm'
                                        onMouseDown={() => {
                                            if (checkAndAlertSpecialtyTabFormErrors()) {
                                                return;
                                            }
                                            handleUpdateUserProfile({...profileForm});
                                        }}>
                                        Finish
                                    </Button>
                                </div>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default AgentSignUpInformationModal;
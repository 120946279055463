import React, { useEffect, useState } from 'react';
import axios from 'axios'
import {
    Avatar,
    Center,
    ButtonGroup,
    Button,
    Icon,
    IconButton,
    Text,
    Tooltip,
    Heading,
    Flex,
    Stack,
    HStack
} from '@chakra-ui/react';

import {
    ADDRESS_LINE_ONE,
    COORDINATES,
    GET,
    RESIROOTS_DEFAULT_OFFER,
    ID,
    FIRM,
    FIRST_NAME,
    LAST_NAME,
    PROFILE_PICTURE_URL,
    ABOUT_DESCRIPTION,
    LATITUDE,
    LONGITUDE,
    LICENSE_TYPE,
    REPRESENTATION_STYLE,
    WORKING_STYLE,
    ASSET_SPECIALTY,
    SALE_EXPERTISE,
    LICENSE_NUMBER,
    DEFAULT_GET_TIMEOUT,
    NOT_INTERESTED_BY_BUYER
} from '../../../utils/constants.js';

import {
    FaCircleInfo,
    FaUserPlus, 
    FaElementor, 
    FaUserTie, 
    FaWrench, 
    FaHouseUser, 
    FaEyeSlash 
} from 'react-icons/fa6';

import {
    InfoIcon
} from '@chakra-ui/icons';

import { 
    getAssetSpecialtyIcon,
    prettifyLicenseType,
    prettifyPropertyType,
    prettifySaleExpertise,
    renderWorkingStyle
} from '../../../utils/utility.js';

import './AgentCard.css';
import '../../Generic.css';
import TransactionsTable from '../../agent/Transactions/TransactionsTable.js';

import Radar from 'radar-sdk-js';

function AgentCard(props) {

    const agent = props.agent;
    const handleAddConnection = props.handleAddConnection;
    const triggerFetchAgents = props.triggerFetchAgents;

    const [agentCardLoading, setAgentCardLoading] = useState(true);
    const [agentTransactions, setAgentTransactions] = useState(null);
    const [agentAreasOfExpertise, setAgentAreasOfExpertise] = useState(null);

    useEffect(() => {
        axios.all([
            axios({
                method: GET,
                url: '/api/v1/user/agent/transactions/' + agent[ID],
                timeout: DEFAULT_GET_TIMEOUT,
            }),
            axios({
                method: GET,
                url: '/api/v1/user/agent/expertise_areas/' + agent[ID],
                timeout: DEFAULT_GET_TIMEOUT,
            })
        ]).then(axios.spread((transactionsResponse, expertiseAreasResponse) => {
            if (200 === transactionsResponse.status) {
                let transactions = transactionsResponse.data;
                setAgentTransactions(transactions);
            }
            if (200 === expertiseAreasResponse.status) {
                let expertiseAreas = expertiseAreasResponse.data;
                setAgentAreasOfExpertise(expertiseAreas);
            }
            setAgentCardLoading(false);
        })).catch(error => {
            setAgentCardLoading(false);
        }) 
    }, [agent]);

    // const hasWorkingAgent = props.hasWorkingAgent;
    const [defaultOffer, setDefaultOffer] = useState(props.defaultOffer);
    
    const [setAndRenderContactAgentModal] = useState(props.setAndRenderContactAgentModal);

    useEffect(() => {
        if (defaultOffer) setDefaultOffer(RESIROOTS_DEFAULT_OFFER);
    }, [defaultOffer]);

    /*
     * Name: AgentInformation
     * Description: renders the offer portion of an agent card.
     * Parameters: None
     */
    const AgentInformation = (props) => {

        const agentAreasOfExpertise = props.agentAreasOfExpertise;
        console.log(agentAreasOfExpertise);

        const [selectedArea, setSelectedArea] = useState(null);

        useEffect(() => {
            if (agentAreasOfExpertise && agentAreasOfExpertise.length > 0) {
                let selectedArea = agentAreasOfExpertise[0];
                setSelectedArea(selectedArea);
            }
        }, [agentAreasOfExpertise]);

        console.log("here");

        if (selectedArea) {
            let latitude = selectedArea[COORDINATES][LATITUDE];
            let longitude = selectedArea[COORDINATES][LONGITUDE];

            let map = new Radar.ui.map({
                container: 'map-container',
                style: 'radar-default-v1',
                center: [longitude, latitude],
                zoom: 14,
            });
            Radar.ui.marker({ text: selectedArea[ADDRESS_LINE_ONE] })
                .setLngLat([longitude, latitude])
                .addTo(map);
        }
        
        const AgentIntro = () => {
            return (
                <Stack gap={5} width='350px'>
                    <Stack>
                        <Center>
                            <Avatar src={agent && agent[PROFILE_PICTURE_URL] && agent[PROFILE_PICTURE_URL] !== "" ?
                                agent[PROFILE_PICTURE_URL] : null} size='2xl' className="agent_card__avatar_large" style={{
                                    // border: "4px solid #efefef",
                                }}
                            />
                        </Center>
                        <Stack gap={1}> 
                            <Text fontSize='2xl' fontWeight='bold'>
                                {agent ? agent[FIRST_NAME] + " " + agent[LAST_NAME] : ""}
                            </Text>
                            <Flex gap={2}>
                                <Text color='gray' fontSize='md'>
                                    {agent && agent[FIRM] ? agent[FIRM] : "No Brokerage"}
                                </Text>
                                <Text color='gray'>
                                    •
                                </Text>
                                <Text color='gray'>
                                    {agent && agent[LICENSE_NUMBER] ? agent[LICENSE_NUMBER] : "No License Number"}
                                </Text>
                            </Flex>
                        </Stack>
                    </Stack>
                    <ButtonGroup>
                        <Button
                            leftIcon={<FaUserPlus/>}
                            onMouseDown={(e) => {
                                e.stopPropagation();
                                if (e.target.target === this) {
                                    // If has a working agent, don't let them connect.
                                    // if (hasWorkingAgent) {
                                    //     toast({
                                    //         title: "You can only work with one agent at a time",
                                    //         description: "Unmatch from your current agent to work with another agent",
                                    //         status: 'info',
                                    //         duration: 3000,
                                    //         isClosable: true,
                                    //         position: 'top'
                                    //     });
                                    //     return; 
                                    // }
                                    setAndRenderContactAgentModal(agent)
                                }
                            }}
                            size='sm'
                            className="agent_card__connect_button positive_button hover"
                            style={{
                                zIndex: "2",
                            }}
                        >
                            Connect
                        </Button>
                        <Button
                            leftIcon={<FaEyeSlash/>}
                            onMouseDown={(e) => {
                                e.stopPropagation();
                                if (e.target.target === this) {
                                    // If has a working agent, don't let them connect.
                                    // if (hasWorkingAgent) {
                                    //     toast({
                                    //         title: "You can only work with one agent at a time",
                                    //         description: "Unmatch from your current agent to work with another agent",
                                    //         status: 'info',
                                    //         duration: 3000,
                                    //         isClosable: true,
                                    //         position: 'top'
                                    //     });
                                    //     return; 
                                    // }
                                    handleAddConnection(agent[ID], NOT_INTERESTED_BY_BUYER, triggerFetchAgents)
                                }
                            }}
                            size='sm'
                            className="agent_card__connect_button negative_light_grey_full_button hover"
                            style={{
                                borderRadius: "12px !important",
                                zIndex: "2",
                            }}
                        >
                            Not Interested
                        </Button>
                        <IconButton 
                            icon={<FaUserPlus/>}
                            onMouseDown={(e) => {
                                e.stopPropagation();
                                if (e.target.target === this) {
                                    // If has a working agent, don't let them connect.
                                    // if (hasWorkingAgent) {
                                    //     toast({
                                    //         title: "You can only work with one agent at a time",
                                    //         description: "Unmatch from your current agent to work with another agent",
                                    //         status: 'info',
                                    //         duration: 3000,
                                    //         isClosable: true,
                                    //         position: 'top'
                                    //     });
                                    //     return; 
                                    // }
                                    setAndRenderContactAgentModal(props.agent)
                                }
                            }} 
                            size='sm'
                            className="agent_card__connect_icon_button positive_button hover"
                            style={{
                                zIndex: "2",
                            }}
                        />
                    </ButtonGroup>
                    <Stack gap={3}>
                        <Heading fontWeight='bold' fontSize='lg'>
                            Intro
                        </Heading>
                        <Stack gap={2}>
                            <Flex align='center' gap={2}>
                                <FaElementor style={{
                                    color: "grey",
                                    height: "15px",
                                    width: "15px",
                                }}/>
                                <Text color='gray' fontSize='xs'>
                                    LICENSE TYPE
                                </Text>
                            </Flex>
                            <Text fontSize='sm'>
                                {agent && agent[LICENSE_TYPE] ? prettifyLicenseType(agent[LICENSE_TYPE]) : "N/A"}
                            </Text>
                        </Stack>
                        <Stack gap={2}>
                            <Flex align='center' gap={2}>
                                <FaUserTie style={{
                                    color: "grey",
                                    height: "15px",
                                    width: "15px",
                                }}/>
                                <Text color='gray' fontSize='xs'>
                                    REPRESENTATION STYLE
                                </Text>
                            </Flex>
                            <Text fontSize='sm'>
                                {agent && agent[REPRESENTATION_STYLE] ? agent[REPRESENTATION_STYLE] : "N/A"}
                            </Text>
                        </Stack>
                        <Stack gap={2}>
                            <Flex align='center' gap={2}>
                                <FaWrench style={{
                                    color: "grey",
                                    height: "15px",
                                    width: "15px",
                                }}/>
                                <Text color='gray' fontSize='xs'>
                                    WORKING STYLE
                                </Text>
                            </Flex>
                            <Text fontSize='sm'>
                                {agent && agent[WORKING_STYLE] ? renderWorkingStyle(agent[WORKING_STYLE]) : "N/A"}
                            </Text>
                        </Stack>
                        <Stack gap={2}>
                            <Flex align='center' gap={2}>
                                <FaHouseUser style={{
                                    color: "grey",
                                    height: "15px",
                                    width: "15px",
                                }}/>
                                <Text color='gray' fontSize='xs'>
                                    SALE EXPERTISE
                                </Text>
                            </Flex>
                            <Text fontSize='sm'>
                                {agent && agent[SALE_EXPERTISE] ? prettifySaleExpertise(agent[SALE_EXPERTISE]) : "N/A"}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            );
        }

        const AgentAboutDescription = () => {
            return (
                <Stack>
                    <Heading fontSize='lg' fontWeight='bold'>
                       About {agent && agent[FIRST_NAME] ? agent[FIRST_NAME] : "this agent"} 
                    </Heading>
                    <Text fontSize='md' color='gray'>
                        {agent && agent[ABOUT_DESCRIPTION] ? agent[ABOUT_DESCRIPTION] : "This agent does not have an intro yet"}
                    </Text>
                </Stack>
            )
        }

        const AgentAssetSpecialties = () => {
            if (!agent || !agent[ASSET_SPECIALTY]) return null;

            let specialties = [];

            for (let i = 0; i < agent[ASSET_SPECIALTY].length; i++) {
                let specialty = agent[ASSET_SPECIALTY][i];
                specialties.push(
                    <Flex align='center' gap={2}>
                        <Icon as={getAssetSpecialtyIcon(specialty)} color='gray'/>
                        <Text color='gray' fontSize='md'>
                            {prettifyPropertyType(specialty)}
                        </Text>
                    </Flex>
                );
            }

            return (
                <Stack gap={3}>
                    <Stack gap={1}>
                        <Heading fontWeight='bold' fontSize='lg'>
                            Here's what {agent && agent[FIRST_NAME] ? agent[FIRST_NAME] : "this agent"}'s an expert on
                        </Heading>
                        <Text fontSize='xs' color='gray'>
                            These are different types of property classes your agent has marked as extremely familar and experienced with
                        </Text>
                    </Stack>
                    <Flex gap={4}>
                        {specialties}
                    </Flex>
                </Stack>
                
            );
        }

        const AgentTransactions = () => {
            return (
                <Stack>
                    <Heading fontWeight='bold' fontSize='lg'>
                        Transactions
                    </Heading>
                    <TransactionsTable transactions={agentTransactions} readOnlyView={true}/>
                </Stack>
            )
        }

        const renderAgentAreasOfExpertise = () => {

            let formattedAreas = [];
            if (agentAreasOfExpertise) {
                for (let i = 0; i < agentAreasOfExpertise.length; i++) {
                    let area = agentAreasOfExpertise[i];
                    formattedAreas.push(
                        <Flex className="hover" 
                            onMouseDown={() => setSelectedArea(area)}
                            style={{
                                backgroundColor: selectedArea && selectedArea.id === area.id ? "#05b96b" : "#dadada",
                                borderRadius: "12px",
                                cursor: "pointer",
                                margin: "0px 0px 10px 0px",
                                padding: "7.5px 12.5px 7.5px 12.5px",
                        }}>
                            <p style={{
                                color: selectedArea && selectedArea.id === area.id ? "white" : "black",
                                fontSize: "0.9em"
                            }}>
                                {agentAreasOfExpertise[i].formatted_address}
                            </p>
                        </Flex>
                    );
                }
            }

            return (
                <Stack gap={4} style={{
                    height: agentAreasOfExpertise && agentAreasOfExpertise.length > 0 ? '' : '400px',
                    width: "100%"
                }}>
                    <Stack gap={1}>
                        <Heading fontWeight='bold' fontSize='lg'>
                            Areas of Expertise
                        </Heading>
                        <Text color='gray' fontSize='xs'>
                            These are areas your agent has marked as areas they're extremely familar and experienced with
                        </Text>
                    </Stack>
                    {
                        agentAreasOfExpertise && agentAreasOfExpertise.length > 0 ?
                        <Stack gap={2}>
                            <Flex gap={2}> 
                                {formattedAreas}
                            </Flex>
                            <div id="map-container" style={{
                                backgroundColor: "#f4f4f4",
                                borderRadius: "12px",
                                // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                display: "block",
                                height: "350px",
                                flexGrow: "1"
                            }}/>
                        </Stack> : 
                        <Center style={{
                            backgroundColor: "#f4f4f4",
                            borderRadius: "12px",
                            padding: "50px"
                        }}>
                            <FaCircleInfo style={{
                                color: "grey",
                                height: "12.5px",
                                width: "12.5px",
                            }}/>
                            <p style={{
                                color: "grey",
                                fontSize: "0.9em",
                                margin: "0px 0px 0px 7.5px",
                                textAlign: "center",
                            }}>
                                This agent does not have any areas of expertise yet
                            </p>
                        </Center>
                    }
                </Stack>
            );
        }

        return (
            <Flex>
                <AgentIntro/>
                <Stack gap={6} flex='1'>
                    <AgentAboutDescription/>
                    <AgentAssetSpecialties/>
                    <AgentTransactions/>
                    {renderAgentAreasOfExpertise()}
                </Stack>
            </Flex>
        );
    }

    if (agentCardLoading) return;

    return <AgentInformation agentAreasOfExpertise={agentAreasOfExpertise}/>;
}

export default AgentCard;
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import { axiosGetUser } from './common/methods/methods.js';
import {
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    Textarea,
    Tooltip,
    useToast
} from '@chakra-ui/react';
import { dotPulse } from 'ldrs';
import { EmailIcon } from '@chakra-ui/icons'
import ViewNavigationBar from './common/ViewNavigationBar.js';
import { 
    isValidEmail,
 } from '../utils/utility.js';

import NavigationBar from './NavigationBar.js';
import Footer from './Footer.js';

import './ContactUsPage.css';
import './SignUpPage.css';
import './Generic.css';

import {
    EMAIL,
    ID,
    FIRST_NAME,
    FORM_FIRST_NAME,
    FORM_LAST_NAME,
    FORM_EMAIL,
    LAST_NAME,
    MESSAGE,
    POST,
    DEFAULT_POST_TIMEOUT
} from '../utils/constants.js';

function ContactUsPage(props) {

    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const urlParams = useParams();
    const [ searchParams ] = useSearchParams();
    const [ textareaValue, setTextareaValue ]  = useState(null);

    const [submissionForm, setSubmissionForm] = useState([]);
    const [submissionLoading, setSubmissionLoading] = useState(false);

    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [messageError, setMessageError] = useState(null);

    const userId = urlParams[ID];
    const viewType = searchParams.get("view_type");
    const toast = useToast();

    dotPulse.register();

    useEffect(() => {
        if (!userId) {
            setUserLoading(false);
            return;
        }
        axiosGetUser(userId).then(user => {
            if (user) {
                setUser(user);
                setUserLoading(false);
                submissionForm[FIRST_NAME] = user[FIRST_NAME];
                submissionForm[LAST_NAME] = user[LAST_NAME];
                submissionForm[EMAIL] = user[EMAIL];
            }
        }).catch(error => {
            // TODO
        });
    }, [userId]);

    const checkAndAlertSubmissionFormErrors = () => {
        if (submissionForm === null || submissionForm === undefined) {
            return true;
        }

        var hasErrors = false;

        // Check first name errors.
        if (!submissionForm[FORM_FIRST_NAME] || submissionForm[FORM_FIRST_NAME].length === 0) {
            hasErrors = true;
            setFirstNameError("First Name is required");
        } else if (submissionForm[FORM_FIRST_NAME].length > 90) {
            hasErrors = true;
            setFirstNameError("First Name cannot be more than 90 characters");
        }

        // Check last name errors.
        if (!submissionForm[FORM_LAST_NAME] || submissionForm[FORM_LAST_NAME].length === 0) {
            hasErrors = true;
            setLastNameError("Last Name is required");
        } else if (submissionForm[FORM_LAST_NAME].length > 90) {
            hasErrors = true;
            setLastNameError("Last Name cannot be more than 90 characters");
        }

        // Check email errors.
        if (!submissionForm[FORM_EMAIL] || submissionForm[FORM_EMAIL].length === 0) {
            hasErrors = true;
            setEmailError("Email is required");
        } else if (!isValidEmail(submissionForm[FORM_EMAIL])) {
            hasErrors = true;
            setEmailError("Email is invalid");
        } else if (submissionForm[FORM_EMAIL].length > 255) {
            hasErrors = true;
            setEmailError("Email cannot be more than 255 characters");
        }

        // Check message errors.
        if (!submissionForm[MESSAGE] || submissionForm[MESSAGE].length === 0) {
            hasErrors = true;
            setMessageError("Message is required");
        } else if (submissionForm[MESSAGE].length > 500) {
            hasErrors = true;
            setMessageError("Message cannot be more than 500 characters");
        }

        return hasErrors;
    }

    const handleContactUsSubmissionFormSubmit = () => {

        setSubmissionLoading(true);

        if (checkAndAlertSubmissionFormErrors()) {
            setSubmissionLoading(false);
            return;
        }

        axios({
            method: POST,
            url: '/api/v1/contact_us',
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                first_name: submissionForm[FIRST_NAME],
                last_name: submissionForm[LAST_NAME],
                email: submissionForm[EMAIL],
                message: submissionForm[MESSAGE],
            }
        }).then(response => {
            if (200 === response.status) {
                toast({
                    title: "Submitted",
                    description: "Thanks for contacting us. A representative will reach out as soon as possible",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
                setTextareaValue(null);
                setSubmissionLoading(false);
            }
        }).catch(error => {
            if (400 === error.response.status) {
                toast({
                    title: "Missing required fields",
                    description: "There was an error submitting your form. Please fix your form's errors",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            } else {
                toast({
                    title: "Unable to submit form",
                    description: "There was an error submitting your form. Please try again later",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
            setSubmissionLoading(false);
        })
    }

    const handleSubmissionFormChange = (e) => {
        if (e.target.name === FIRST_NAME) {
            setFirstNameError(null);
        } else if (e.target.name === LAST_NAME) {
            setLastNameError(null);
        } else if (e.target.name === EMAIL) {
            setEmailError(null);
        }
        submissionForm[e.target.name] = e.target.value;
    }

    if (userLoading) {
        return;
    }

    return (
        <div>
            <div className="contact-us-page">
                {
                    user ? 
                    <ViewNavigationBar user={user} viewType={viewType} displaySwitchButton={false}/>:
                    <div className="contact_us__non_user_page">
                        <NavigationBar isUserPage={false}/>
                    </div>
                }
                <div className="contact_us__form_parent">
                    <p className="contact_us__form_title">
                        Contact Us
                    </p>
                    <p style={{
                        color: "#5A5A5A",
                        fontSize: "1.0em",
                        textAlign: "center",
                    }}>
                        Have an issue or feedback? We're all ears.
                    </p>
                    <br></br>
                    <form className="contact_us__form">
                        <div className="contact_us__form_name_input_block">
                            <div className="contact_us__form_name_input">
                                <label className="signup-page-form-label">
                                    First Name
                                </label>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={firstNameError}
                                    isOpen={firstNameError !== null}
                                    placement='top-end'
                                >
                                    <Input
                                        className="default_input"
                                        name={FORM_FIRST_NAME}
                                        onChange={handleSubmissionFormChange}
                                        placeholder={user ? user[FIRST_NAME] : ''}
                                    />
                                </Tooltip>
                            </div>
                            <div className="contact_us__form_name_input">
                                <label className="signup-page-form-label">
                                    Last Name
                                </label>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={lastNameError}
                                    isOpen={lastNameError !== null}
                                    placement='top-end'
                                >
                                    <Input
                                        className="default_input"
                                        name={FORM_LAST_NAME}
                                        onChange={handleSubmissionFormChange}
                                        placeholder={user ? user[LAST_NAME] : ''}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{
                            margin: "15px 0px 0px 0px",
                        }}>
                            <label className="signup-page-form-label">
                                Email
                            </label>
                                
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={emailError}
                                isOpen={emailError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <EmailIcon color='#cccccc'/>
                                    </InputLeftElement>
                                    <Input
                                        className="default_input"
                                        name={FORM_EMAIL}
                                        onChange={handleSubmissionFormChange}
                                        placeholder={user ? user[EMAIL] : ''}
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            margin: "15px 0px 0px 0px",
                        }}>
                            <label className="signup-page-form-label">
                                Message
                            </label>
                            <InputGroup>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={messageError}
                                    isOpen={messageError !== null}
                                    placement='top-end'
                                >
                                    <Textarea
                                        className="default_input"
                                        name={FORM_EMAIL}
                                        onChange={(e) => {
                                            setMessageError(null);
                                            setTextareaValue(e.target.value);
                                            submissionForm[MESSAGE] = e.target.value;
                                        }}
                                        placeholder="How can we help?"
                                        resize={'none'}
                                        style={{
                                            height: "200px"
                                        }}
                                    />
                                </Tooltip>
                            </InputGroup>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "2.5px 0px 0px 0px",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.8em",
                            }}>
                                {500 - ( textareaValue ? textareaValue.length : 0)} / 500 characters left
                            </p>
                        </div>
                        <Button
                            className="hover"
                            onMouseDown={() => {
                                handleContactUsSubmissionFormSubmit();
                            }} 
                            style={{
                            backgroundColor: "#05b96b",
                            borderRadius: "12px",
                            color: "white",
                            margin: "10px 0px 0px 0px",
                            width: "100%",
                        }}>
                            {
                                submissionLoading ?
                                <l-dot-pulse
                                    size="35"
                                    speed="1.25" 
                                    color="white" 
                                ></l-dot-pulse> :
                                <>Contact Us</>
                            }
                        </Button>
                    </form>
                    {
                        user ? 
                        <div style={{
                            color: "grey",
                            margin: "15px 0px 0px 0px",
                            textAlign: "center",
                        }}>
                            <Link href="/auth/login" style={{
                                fontSize: "0.9em",
                            }}>Not {user[FIRST_NAME]}? Sign Out</Link>
                        </div> : null
                    }
                </div>
            </div>
            {
                user ?
                null : 
                <Footer/>
            }
        </div>
    );
}

export default ContactUsPage;
